import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IVendorOnboardingResult } from 'src/app/store/vendor/vendor.model';
import {
  ICapabilitytProcess,
  ICapability,
  SupplierListModel,
} from 'sustainment-models';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as ManufacturingHelper from 'sustainment-component';

@Component({
  selector: 'app-company-preselection',
  templateUrl: './company-preselection.component.html',
  styleUrls: ['./company-preselection.component.scss'],
})
export class CompanyPreselectionComponent implements OnInit {
  @Input() public vendor: SupplierListModel;

  @Input() public processes: ICapabilitytProcess[];
  @Input() public industries: ICapability[];

  @Input() public originalProcesses: ICapabilitytProcess[] = [];

  @Output() public selected = new EventEmitter<IVendorOnboardingResult>();
  @Output() public canceled = new EventEmitter<void>();
  @Output() public enableContinue = new EventEmitter<SupplierListModel>();

  public ngOnInit(): void {
    if (this.vendor && this.vendor && this.vendor.processes)
      this.vendor.processes =
        ManufacturingHelper.mapVendorProfileProcessSubprocess(
          this.vendor,
          this.originalProcesses,
        );
  }

  public onToggleCardSelection(vendor: SupplierListModel): void {
    this.enableContinue.next(vendor);
  }

  public onSelectClick(): void {
    this.processes = ManufacturingHelper.setUsingCapabilityCodes(
      this.vendor?.processes || [],
      this.processes,
    );

    this.industries = ManufacturingHelper.setUsingCapabilityCodes(
      this.vendor?.industries || [],
      this.industries,
    );

    const processesBeingUsed = ManufacturingHelper.getProcessesInUse(
      this.processes,
    );
    const industriesBeingUsed = ManufacturingHelper.getIndustriesInUse(
      this.industries,
    );

    const vendor: IVendorOnboardingResult = {
      name: this.vendor.name,
      //TODO: Missing Unit
      primaryAddress: {
        address: this.vendor?.address?.addressLine,
        postalCode: this.vendor?.address.zip,
        state: this.vendor.address.state,
        latitude: this.vendor.address?.latitude,
        longitude: this.vendor.address?.longitude,
        city: this.vendor?.address?.city,
        county: this.vendor?.address?.county,
        country: this.vendor?.address?.country,
      },
      logo: this.vendor?.logo || null,
      website: this.vendor?.website || null,
      processes: processesBeingUsed,
      industries: industriesBeingUsed,
      sustainmentId: this.vendor.sustainmentId,
      isBuyer: false,
      isRawMaterial: false,
      isSeller: false,
      isSupplier: true,
      isSupport: false,
    };

    this.selected.emit(vendor);
  }

  public onCancelClick(): void {
    this.canceled.emit();
  }
}
